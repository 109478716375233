/* html,
body {
  background-color: #1b2430;
  height: 100vh;
} */

* {
  /* color: #fff; */
}

h1 {
  font-size: 58px;
}

h2 {
  font-size: 28px;
  margin-bottom: 26px;
}

h3 {
  font-size: 22px;
  margin: 6px;
}

.App {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.innerWrapper {
  width: 100vw;
  height: 100%;
  /* border: 1px solid white; */
  max-width: 1400px;
  padding-top: 20px;
}

.itemsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 30px;
  height: 80vh;
}



.itemsWrapper  > div > div {
  box-shadow: inset 0 0 0.5px 1px hsla(255, 255%, 100%, 0.075),
     0 0 0 1px hsla(255, 255%, 255%, 0.05),
     0 0.3px 0.9px hsla(255, 255%, 255%, 0.02),
    0 0.9px 1.9px hsla(255, 255%, 255%, 0.045),
    0 3.5px 9px hsla(255, 255%, 255%, 0.09);
  margin: 40px 0px;
  background-color: rgba(255, 255, 255, 0.04);
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px ;
  
}

/* .itemsWrapper > div  div:nth-child(odd) {
  border-radius: 0px 12px 12px 0px;
}

.itemsWrapper > div > div:nth-child(even) {
  border-radius: 12px 0px 0px 12px;
} */

/* .itemsWrapper > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media (max-width: 768px) {
  /* .itemsWrapper {
    justify-content: center;
    padding-top: 30px;
  }
  .itemsWrapper > div {
    width: 90%;
  }
  .itemsWrapper div:nth-child(odd) {
    border-radius: 12px;
  }
  .itemsWrapper div:nth-child(even) {
    border-radius: 12px;
  } */
}

.loadingOverlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.4); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.orderWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 96vh;
  overflow-y: hidden;
  flex-direction: column;
  padding-top: 30px;
}

.orderWrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.orderWrapper > div > h1 {
  font-size: 90px;
  margin: 18px 30px;
}

@media (min-width: 768px) {
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 90px;
  margin-bottom: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 94px;
  height: 94px;
  margin: 8px;
  border: 8px solid rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
